<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon icon="CheckCircleIcon" size="18" />
            <h4 class="mb-0 ml-75">Verificação</h4>
          </div>

          <span
            >Envie como anexo seu documento de identidade (frente e verso), CPF
            e Registro Profissional</span
          >
        </b-col>

        <DragAndDropComponent @update:files="files = $event" />
      </b-row>
      <b-row>
        <b-col>
          <b-alert variant="warning" show class="mt-2">
            <div class="alert-body" v-if="!localOptions.registrationActivated">
              <span
                ><strong>Sua Conta ainda não foi verificada! </strong>Envie seus
                documentos para análise!</span
              >
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="onSubmit()"
          >
            Salvar Modificações
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1 ml-25"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Reverter Modificações
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DragAndDropComponent from '@/components/input/DragAndDrop.vue'
import usersService from '@/services/usersService'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    DragAndDropComponent,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      files: [],
      localOptions: JSON.parse(JSON.stringify(this.socialData)),
    }
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.socialData))
      this.files = []
    },
    onSubmit() {
      if (this.files) {
        const formData = new FormData()
        formData.append('file', this.files[0])
        usersService.sendDocuments(formData)
      }
    },
  },
}
</script>
