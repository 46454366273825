// import axiosIns from '@/libs/axios'

// const controller = 'account'

export default {
  getPayloadJwt() {
    const token = localStorage.getItem('accessToken')
    return JSON.parse(atob(token.split('.')[1]))
  },
  getUserLogged() {
    return JSON.parse(localStorage.getItem('userData'))
  },
  getLogin() {
    return this.getUserLogged().username
  },
}
