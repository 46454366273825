import axiosIns from '@/libs/axios'
import authService from './authService'

const controller = 'users'

export default {
  async getAll() {
    return axiosIns.get(controller).then(res => res.data.content)
  },
  async getById(id) {
    return axiosIns.get(`${controller}/${id}`).then(res => res.data)
  },
  async getByLogin(login) {
    return axiosIns.get(`${controller}?login=${login}`).then(res => res.data.content[0])
  },
  async update(obj) {
    return axiosIns.put(controller, obj).then(res => res.data)
  },
  async create(req) {
    return axiosIns.post(controller, req).then(res => res.data)
  },
  async changePassword(req) {
    return axiosIns.put(`${controller}/change-password`, req).then(res => res.data)
  },
  async sendDocuments(req) {
    return axiosIns.post(`${controller}/document/person-file-type/CPF`, req).then(res => res.data)
  },
}
