<template>
  <div id="file-drag-drop">
    <div class="d-flex flex-wrap col-12" :v-if="files.length > 0">
      <div
        class="badge badge-pill badge-primary mr-1 mt-1"
        style="padding: 5px 7px"
        v-for="item in files"
        :key="item.name"
      >
        {{ item.name }}
        <button
          class="btn"
          title="Remover"
          style="padding: 5px"
          @click="removeFile(item)"
        >
          <feather-icon class="text-white" icon="XIcon" />
        </button>
      </div>
    </div>
    <form ref="fileform" @drop.prevent="onUpload" :class="{ over: isOver }">
      <input
        multiple
        ref="inputFile"
        type="file"
        class="d-none"
        @change="onUpload"
      />
      <span class="drop-files">Arraste e solte os arquivos aqui</span>
      <div class="col-12 text-center">Ou</div>
      <div class="div">
        <b-button
          variant="primary"
          class="button-right ml-1"
          @click="$refs.inputFile.click()"
        >
          <feather-icon icon="UploadIcon" class="mr-50" />
          <span class="align-middle">Selecionar Arquivos</span>
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { hideSpinner, showSpinner } from '@/services/spinService'
const events = ['dragenter', 'dragover', 'dragleave', 'drop']

export default {
  components: {
    BButton,
  },
  data() {
    return {
      files: [],
      isOver: false,
    }
  },
  methods: {
    onUpload(e) {
      showSpinner()
      const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      [].slice.call(files).map(file => {
        this.files.push(file)
      })
      hideSpinner()
      this.$emit('update:files', this.files)
    },
    removeFile(file) {
      this.files = this.files.filter(
        fileInlist => fileInlist.lastModified !== file.lastModified
      )

      this.$emit('update:files', this.files)
    },
  },
  mounted() {
    this.files = []
    events.forEach(eventName => {
      document.body.addEventListener(eventName, e => e.preventDefault())
    })
    this.$refs.fileform.addEventListener('dragover', () => {
      this.isOver = true // add class on drag over
    })
    this.$refs.fileform.addEventListener('dragleave', () => {
      this.isOver = false // remove class on drag leave
    })
    this.$refs.fileform.addEventListener('mouseover', () => {
      this.isOver = false // remove class on drag leave
    })
  },
}
</script>

<style lang="scss">
#file-drag-drop {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  form {
    display: flex;
    height: 200px;
    width: 100%;
    border: 2px dashed var(--primary);
    margin: auto;
    margin-top: 40px;
    flex-wrap: wrap;

    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 4px;
    transition: 0.5s;
    &.over {
      background-color: var(--primary);
      color: white !important;
      border-color: white;
      transition: 0.5s;
    }
  }
}
</style>
